.contact-row {
    display: flex;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #eee;
    cursor: pointer;
  }
  
  .avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #ccc;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    font-size: 16px;
    font-weight: bold;
    color: white;
  }
  
  .contact-info {
    flex: 1;
  }
  
  .contact-name {
    font-weight: bold;
    font-size: 16px;
  }
  
  .last-message {
    font-size: 14px;
    color: #666;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .meta {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  
  .timestamp {
    font-size: 12px;
    color: #999;
  }
  
  .unread-indicator {
    width: 20px;
    height: 20px;
    background-color: #007bff;
    color: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    margin-top: 5px;
  }

.tab-bar {
    display: flex;
    gap: 10px;
    background-color: #f8f8f8;
    padding: 10px;
    border-bottom: 1px solid #ddd;
    overflow-x: auto; /* Allow horizontal scrolling */
  white-space: nowrap; /* Prevent wrapping of the tabs */
}

.print-div {
    @media print {
        padding: 20px 96px 96px 96px;
    }
}

.logo-container {
    text-align: center;
    display: none;
    margin-top: 20px;
    margin-top: 20px;
    @media print {
        display: block;
    }
}

.tab-bar::-webkit-scrollbar {
  height: 6px; /* Customize scrollbar height */
}

.tab-bar::-webkit-scrollbar-thumb {
  background: #ccc; /* Customize scrollbar color */
  border-radius: 3px; /* Rounded corners */
}

.tab-bar::-webkit-scrollbar-thumb:hover {
  background: #aaa; /* Hover effect for scrollbar */
}
  
  .tab-item {
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 8px 8px;
    border-radius: 20px;
    cursor: pointer;
    background-color: #fff;
    border: 1px solid #ddd;
    transition: all 0.3s ease;
  }
  
  .tab-item.active {
    background-color: #0070f3;
    color: #fff;
    border-color: #0070f3;
  }
  
  .tab-count {
    background-color: #d1eaff;
    color: #0070f3;
    padding: 2px 8px;
    border-radius: 50px;
    font-size: 12px;
  }
  
  .tab-item.active .tab-count {
    background-color: #005bb5;
    color: #fff;
  }
  

.web {
    display: block;
}

.mobile-action-modal {
    display: grid;
    gap: 10px;
}

.mobile-action-modal .btn{
width: fit-content;
background-color: #ffffff;
display: flex;
gap: 5px;
}


.responsive-image {
    width: 100%;
    max-width: 70vw;
    max-height: 70vw;
    aspect-ratio: 4 / 3,
}

.mobile {
    display: none;
}

.web-mobile {
    display: flex;
}

.order-detail-right {
    display: grid;
    gap: 20px;
}

.order-proof-container {
    padding: 20px;
}

.Polaris-Modal-Dialog__Modal .Polaris-Button--variantPrimary {
    padding: 0px;
    --pc-button-box-shadow: 0;
    --pc-button-bg-gradient: transparent;

}

.Polaris-Modal-Dialog__Modal .Polaris-Button--variantSecondary {
    padding: 0px;
    --pc-button-box-shadow: 0;
    --pc-button-bg-gradient: transparent;

}

.Polaris-Modal__Body {
    flex-grow: 0 !important;
}

.fixedElement-Lower{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 40px 0px 30px;
}

.Polaris-Page__Content  {
    border-radius: 1px !important;
}

.Polaris-Modal-Dialog__Modal.Polaris-Modal-Dialog--sizeFullScreen {
    width: 98% !important;
    max-width: 98% !important;
}
/* .Polaris-Button--pressable {
    color: #FFFFFF !important;
} */
.Polaris-DropZone__Container {
    display: flex;
    align-items: center;
}

.Polaris-Button--toneSuccess {
    color: #FFFFFF !important;
}
.Polaris-Button--variantPrimary .Polaris-Text--root {
    color: #FFFFFF !important;
}
.Polaris-Button--toneSuccess .Polaris-Text--root {
    color: #FFFFFF !important;
}
.Polaris-TextField__Input {
    color: #000000 !important;
}
.Polaris-Text--subdued {
    color: #000000 !important;
}

.Polaris-Link {
    text-decoration: none !important;
}

.Polaris-Badge {
    text-transform: capitalize !important;
}

.Polaris-Link:hover {
    text-decoration: none;
}

.fixedElement {
    position:fixed;
    top:0;
    width:100%;
    z-index:100;
    display: flex;
    padding: 10px 24px;
    align-items: center;
    justify-content: space-between;
    background-color: #f7f7f7;
}
#order-items-box {
    background-color: #FFFFFF;
    border-radius: 10px;
}

#order-items-box .right-column-body {
    padding: 10px 15px;
}


#order-items-box .item {
    display:flex;
    align-items: start;
    gap:20px;
    margin:10px 0px;
    padding: 10px;
    background-color: #FFFFFF;
    border-radius: 8px;
    border: 1px solid #e3e3e3;
}
#order-conatainer-box {
    background-color: #FFFFFF;
    border-radius: 10px;
}
#order-conatainer-box .column-header {
    display: flex;
    justify-content: flex-start;
    gap: 10px;
}
#order-conatainer-box .column-body {
    padding: 10px 15px !important;
}
#comment-box {
    z-index: 1;
    position: relative;
    background-color: #FFFFFF;
    padding: 10px 0px;
    margin-top: 10px;
    border-radius: 10px;
    border-radius: 10px 10px 10px 10px;
}
#comment-post {
    background-color: #F7F7F7;
    padding-top: 10px;
    border-radius: 10px;
    border-radius: 0px 0px 10px 10px ;
}

#notes-box {
    background-color: #FFFFFF;
    border-radius: 10px;
}
#sent-proof-box {
    background-color: #FFFFFF;
    border-radius: 10px;
}
#artist-box {
    background-color: #FFFFFF;
    border-radius: 10px;
}
#proof-file-box {
    background-color: #FFFFFF;
    border-radius: 10px;
}
#internal-file-box {
    background-color: #FFFFFF;
    border-radius: 10px;
}
#custom-box {
    background-color: #FFFFFF;
    border-radius: 10px;
}
.due-date-container {
    display: grid;
    width: 100%;
    row-gap: 10px;  
    margin-top: 10px; 
}

.due-date-container .item {
    display: grid;
    row-gap: 5px;
}

.fixedElement .block{
    display:flex;
    align-items:center;
    color:#000000;
    gap:10px
}

.fixedElement  .A{
    display:flex;
}

.fixedElement .B{
    display:flex;
    justify-content: end;
}

.Polaris-Tabs__Tab .Polaris-Text--root {
    color: #000000;
}

.tabs-container {
    overflow-x: auto;
    white-space: nowrap;
  }
  
  .tabs-container .Polaris-LegacyTabs {
    display: flex;
  }

.Polaris-LegacyTabs__Title {
    width: fit-content !important;
    min-width: fit-content !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.Polaris-LegacyTabs__Tab--selected .Polaris-LegacyTabs__Title::before {
    background: #5c6ac4;
}

.Polaris-LegacyTabs--fitted .Polaris-LegacyTabs__TabContainer {
     flex: none !important;
     margin-left: 8px !important;
}
/* Styling for the wrapper element */
.Polaris-TextField{
    margin-bottom: 0px; /* Example margin bottom */
}

.Polaris-IndexTable__TableHeading {
    color: #000000 !important;
    background-color: transparent!important;
    font-size: 15px !important;
    padding: 10px 18px !important;
}

.Polaris-IndexTable__TableCell {
    color: #000000 !important;
    background-color: transparent!important;
    font-size: 14px !important;
    padding: 10px 18px !important;
}



.pagenation-btns #left-button {
    border-radius: 3px 0px 0px 3px !important;
}

.pagenation-btns #right-button {
    border-radius: 0px 3px 3px 0px !important;
    border-left: none !important;
}

.pagenation-btns {
    display: flex;
    align-items:center;
    gap: 0px;
    float:right;
    border-radius: 3px;
}

.csvexcel {
    display: flex;
    align-items:center;
    align-self: start;
     gap: 6px;
     float:left;
}

.tc-bg {
    background-color: var(--p-color-bg-surface) !important;
}

.Polaris-IndexTable__TableHeading:first-child:not(.Polaris-IndexTable__TableHeading--flush) {
    padding-left: var(--p-space-300);
    background-color: var(--p-color-bg-surface) !important;
}

.csvexcel #csv-btn {
    border-radius: 3px !important;
    padding: 7px 16px !important;
    color: #000000;
    font-size: 14px;
}

.table-footer-container {
    display:flow-root;
    width:100%;
    margin-top: 10px;
}

.order-filter {
    display: flex;
    justify-content:flex-start;
    gap: 10px;
    margin-bottom: 10px;
}

.order-filter #sort-order-id {
    width: 1000px;
}

.customize-container{
    width:100%;
    text-align:end;
    margin-bottom: 20px;
}

.customize-container .Polaris-Button {
    width: auto;
}

.column-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 15px;
}
.column-body {
    align-items: center;
    padding: 5px 25px 45px;
}
.column-footer {
    display: grid; 
    gap: 15px;
    padding: 15px;
    border-top: 1px solid #e3e3e3;
}

.icon-wrapper {
    cursor: pointer;
}

.right-column-body {
    align-items: center;
    padding: 5px 15px 15px;
}

.right-column-footer {
    align-items: center;
    height: 30px;
}

.comment-column-footer {
    padding: 5px 15px;
    display: flex;
    gap: 10px;
    align-items: center;
}

.text-box__input {
    border: none !important;
}

.comment-column-footer .Polaris-TextField__Backdrop {
    border: none; /* Remove border */
    outline: none; /* Remove outline */
}

.comment-column-footer .Polaris-TextField__Backdrop:focus {
    border: none; /* Remove border */
    outline: none; /* Remove outline */
}

.comment-column-footer .Polaris-Connected .Polaris-TextField__Input {
    border: none; /* Remove border */
    outline: none; /* Remove outline */
    font-size: 16px;
}
  
  /* Remove the border and outline on focus */
.comment-column-footer .Polaris-Connected .Polaris-TextField__Input:focus {
    border: none; /* Remove border on focus */
    outline: none; /* Remove outline on focus */
}
  
  /* Optional: You can also remove the background color on focus */
.comment-column-footer .Polaris-Connected .Polaris-TextField__Input:focus {
    background-color: transparent; /* Remove background color on focus */
}

.product-img-container {
    position: relative; /* Ensure relative positioning for badge */
    width: 70px;
    padding: 0px 0px 10px 0px;
  }
  
  /* Style the badge */
  .product-img-container .Polaris-Badge {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 100;
  }

  .scrollable-view {
    max-height: 100px;
    overflow-y: auto; 
  }

  .Polaris-DropZone {
    width: fit-content !important;
  }

  .Polaris-DropZone--hasOutline:not(.Polaris-DropZone--isDisabled)::after {
    border: none !important;
}

.bottom-padding-remove {
 padding-bottom: 0px !important;
}

.top-padding-remove {
    padding-top: 0px !important;
}

/* .Polaris-Button--variantPrimary  {
    --pc-button-bg: var(--p-color-bg-fill-success) !important;
    --pc-button-bg-gradient: var(--p-color-bg-fill-success) !important;
    --pc-button-box-shadow: none !important;
    --pc-button-box-shadow: var(--p-shadow-button-primary-success) !important;
    --pc-button-box-shadow_active: var(--p-shadow-button-primary-success-inset) !important;
    --pc-button-bg: var(--p-color-bg-fill-success) !important;
    --pc-button-bg_hover: var(--p-color-bg-fill-success-hover) !important;
    --pc-button-bg_active: var(--p-color-bg-fill-success-active) !important;
    --pc-button-bg_pressed: var(--p-color-bg-fill-success-selected) !important;
} */

.banner {
    width: 100%;
    height: max-content;
    padding: 16px;
    border: 1px solid #ccc;
    border-radius: 1px 1px 3px 3px;
    display: flex;
    gap: 20px;
    margin: 20px 0px;
}

.banner-success {
    border-top: 3px solid #bcde9e !important;
}

.banner-rejected {
    border-top: 3px solid #e0726e !important;
}

.banner-waiting {
    border-top: 3px solid #5072cb !important;
}

.banner-body {
    display: grid;
    gap: 10px;
    width: 100%;
}

.banner-body-list {
    color: #979fb8;
    display: grid;
    gap: 3px;
}

.icon-rejected-wrapper {
    background-color: #e0726e;
    border: 3px solid #e0726e;
    border-radius: 50px;
    height: 33px;
    width: 33px;
}

.icon-rejected-wrapper .Polaris-Icon {
    width: 30px;
    height: 30px;
}

.icon-waiting-wrapper {
    background-color: #5072cb;
    border: 3px solid #5072cb;
    border-radius: 50px;
    height: 33px;
    width: 33px;
}

.icon-waiting-wrapper .Polaris-Icon {
    width: 30px;
    height: 30px;
}

.icon-waiting-wrapper .Polaris-Icon__Svg,
.icon-rejected-wrapper .Polaris-Icon__Svg,
.icon-success-wrapper .Polaris-Icon__Svg {
    color: #000000 !important;
}


.icon-success-wrapper {
    background-color: #bcde9e;
    border: 3px solid #bcde9e;
    border-radius: 50px;
    height: 36px;
    width: 36px;
}

.icon-success-wrapper .Polaris-Icon {
    width: 33px;
    height: 33px;
}

.banner-action {
    display: flex !important;
    gap: 5px;
}

.banner-action .Polaris-Button--variantSecondary  {
    --pc-button-bg: transparent !important;
    --pc-button-color: #0e1216;
    --pc-button-box-shadow: var(--p-shadow-button-primary-success) !important;
    --pc-button-box-shadow_active: var(--p-shadow-button-primary-success-inset) !important;
    --pc-button-bg_hover: var(--pc-button-bg) !important;
    --pc-button-bg_active: var(--pc-button-bg) !important;
    --pc-button-bg_pressed: var(--pc-button-bg) !important;
    border-radius: var(--p-border-radius-100);
    box-shadow: none;
    border: 1px solid rgba(99,115,129,0.4);
    color: #0e1216 !important;
    font-weight: 300;
}

.banner-action .Polaris-Button--variantSecondary .Polaris-Text--root {
    color: #0e1216 !important;
}

.setting-col {
    display: flex;
    cursor: pointer;
}


.setting-icon-wrapper {
    width: 40px;
    height: 40px;
    padding: 5px;
    margin-right: 16px;
    border-radius: 3px;
}

.setting-icon-wrapper .Polaris-Icon {
    width: 30px;
    height: 30px;
}

.settings-tab-header {
    display:flex;
    width:fit-content;
    gap:5px;
    color:#637381;
    cursor:pointer;
    margin-bottom: 10px
}

.Polaris-Button__Icon .Polaris-Icon {
    cursor: pointer !important;
    /* color: #FFFFFF !important; */
}
.Polaris-Button__Icon .Polaris-Icon :hover {
    cursor: pointer !important;
    /* color: #FFFFFF !important; */
}
/* .Polaris-Button.Polaris-Button svg {
    color: #FFFFFF !important;
    fill: #FFFFFF;
}
.Polaris-Button.Polaris-Button svg :hover {
    color: #FFFFFF !important;
    fill: #FFFFFF;
} */
.Polaris-Icon {
    cursor: pointer !important;
}

.btn {
    color: #303030;
    background-color: #e3e3e3;
    font-weight: 600;
    font-size: 14px;
    text-align: center;
    padding: 8px 10px;
    text-decoration: none;
    border-radius: 7px;
    display: flex;
    justify-content: center;
    min-width: 110px;
    cursor: pointer;
}
.btn:active {
    transform: scale(0.98);
}

.print-btn {
    color: #FFFFFF !important;
    background-color: #303030 !important;
}
.reprint-btn {
    color: #303030;
    background-color: #e3e3e3;
}
.approved-btn {
    background-color: #bcdf9f;
}
.reject-btn {
    background-color: #df726d;
}

.simple-blue-btn {
    color: #007ace;
    font-weight: bold;
    font-size: 14px;
    text-align: start;
    width: 30%;
    cursor: pointer;
}

.simple-blue-text {
    margin: 12px 0px;
    font-size: 14px;
    text-align: start;
}

.reject .Polaris-Button--variantPrimary  {
    --pc-button-box-shadow: var(--p-shadow-button-primary-critical) !important;
    --pc-button-box-shadow_active: var(--p-shadow-button-primary-critical-inset) !important;
    --pc-button-bg: var(--p-color-bg-fill-critical) !important;
    --pc-button-bg_hover: var(--p-color-bg-fill-critical-hover) !important;
    --pc-button-bg_active: var(--p-color-bg-fill-critical-active) !important;
    --pc-button-bg_pressed: var(--p-color-bg-fill-critical-selected) !important;
}
.pointer {
    cursor: pointer;
}

.pdf-container-proof-sent {
    width: 40%;
    aspect-ratio: 12/9;
    background-color: #eb2310;
    border-radius: 5px;
}

.bold{font-weight:bold;}
.time{position:absolute; left:-110px;}
.status-icon{
    background-color: #0e1216;
}
.status-icon .Polaris-Icon {
    display: block;
    height: 30px !important; /* or value equivalent to 20px */
    width: 30px !important; /* or value equivalent to 20px */
    max-height: 100%;
    max-width: 100%;
    margin: auto;
    color: #CCC;
  }

  .timeline-container {
    padding: 80px;
    align-items: center;
    display: flex;
    width: 100%;
  }
  .order-status-wrapper {
    border: none;
    border-color: #cecece;
    border-top: 1px solid #cecece;
    border-bottom: 1px solid #cecece;
  }
  .order-detail-wrapper{
    margin-bottom: 60px;
    row-gap: 20px;
  }
  .order-detail-wrapper .order-number{
    color: #000;
    font-size: 36px;
    font-weight: 800;
    margin-top: 1.25rem;
    margin-bottom: 1.5rem;
  }

  .order-detail-wrapper .order-status{
    color: #F41289;
    letter-spacing: .05em;
    text-transform: uppercase;
    font-weight: 500;
    font-size: .875rem;
    line-height: 1.25rem;
    margin-bottom: .75rem;
  }
  .estimate-time {
    font-size: 16px;
    --tw-text-opacity: 1;
    color: rgb(107 114 128 / var(--tw-text-opacity));
    margin-top: .75rem;
    margin-bottom: 1.5rem;
  }
  .estimate-time-b {
    font-size: 16px;
    --tw-text-opacity: 1;
    color: rgb(0 0 0 / var(--tw-text-opacity));
    font-weight: 600;
  }
.timeline-wrapper {
padding:80px;
min-width: 400px;
font-family: 'Helvetica';
font-size: 14px;
/*border: 1px solid #CCC;*/
}

.StepProgress-Comment {
    position: relative;
    padding-left: 62px;
    list-style: none;
    }
    
    .StepProgress-Comment-item {
    position: relative;
    counter-increment: list;
    padding-left: 10px;
    }
    .StepProgress-Comment-item:not(:last-child) {
    padding-bottom: 20px;
    }
    .StepProgress-Comment-item::before {
    display: inline-block;
    content: '';
    position: absolute;
    left: -30px;
    top: -100px;
    height: 160px;
    width: 10px;
    }
    .StepProgress-Comment-item::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
    left: -39px;
    width: 10px;
    height: 10px;
    border: 2px solid #E3E3E3;
    border-radius: 50%;
    background-color: #FFF;
    }
    .StepProgress-Comment-item.is-done::before {
    border-left: 2px solid #CCC;
    
    }
    .StepProgress-Comment-item-status {
        --tw-text-opacity: 1;
        color: rgb(17 24 39 / var(--tw-text-opacity));
        letter-spacing: .025em;
        text-transform: uppercase;
        font-weight: 600;
        font-size: .75rem;
        line-height: 1rem;
    }
    .StepProgress-Comment-item-comment {
        --tw-text-opacity: 1;
        color: rgb(107 114 128 / var(--tw-text-opacity));
        font-size: .875rem;
        line-height: 1.25rem;
    }
    
    .StepProgress-Comment-item-time {
        --tw-text-opacity: 1;
        color: rgb(156 163 175 / var(--tw-text-opacity));
        font-size: .75rem;
        line-height: 1rem;
        margin-top: .25rem;
    }

    .StepProgress-Comment-item.is-done::after {
        z-index: 10;
        width: 20px;
        height: 20px;
        display: inline-block;
        content: "";
        border-radius: 50%;
        text-align: center;
        line-height: 15px;
        vertical-align: middle;
        border: 4px solid #E3E3E3;
        background-color: #4A4A4A;
        color: #fff;
      }


.StepProgress {
position: relative;
padding-left: 45px;
list-style: none;
}
.StepProgress::before {
display: inline-block;
content: '';
position: absolute;
top: 0;
left: 15px;
width: 10px;
height: 100%;
border-left: 2px solid #CCC;
}
.StepProgress-item {
position: relative;
counter-increment: list;
min-height:100px;
padding-left: 10px;
}
.StepProgress-item:not(:last-child) {
padding-bottom: 20px;
}
.StepProgress-item::before {
display: inline-block;
content: '';
position: absolute;
left: -30px;
top: -40px;
height: 100%;
width: 10px;
}
.StepProgress-item::after {
content: '';
display: inline-block;
position: absolute;
top: 0;
left: -49px;
width: 30px;
height: 30px;
border: 2px solid #CCC;
border-radius: 50%;
background-color: #FFF;
}
.StepProgress-item.is-done::before {
border-left: 2px solid #f41289;
}
.StepProgress-item-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.StepProgress-item-status {
    --tw-text-opacity: 1;
    color: rgb(17 24 39 / var(--tw-text-opacity));
    letter-spacing: .025em;
    text-transform: uppercase;
    font-weight: 600;
    font-size: .75rem;
    line-height: 1rem;
    margin-bottom: 5px;
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.StepProgress-item-comment {
    --tw-text-opacity: 1;
    color: rgb(107 114 128 / var(--tw-text-opacity));
    font-size: .875rem;
    line-height: 1.25rem;
}

.StepProgress-item-time {
    --tw-text-opacity: 1;
    color: rgb(156 163 175 / var(--tw-text-opacity));
    font-size: .75rem;
    line-height: 1rem;
    margin-top: .25rem;
}

.custom-button-search-again {
    padding: 12px 8px;
    text-align: center;
    width: 100%;
    margin-top: 32px;
}
.timeline-wrapper .Polaris-Button--textAlignCenter {
    padding: 12px 8px;
    width: 100%;
    max-width: 585px;
    margin-top: 32px;
    text-align: center;
    background-color: #ff4081; /* Custom background color */
    border: 2px solid #ff4081; /* Custom border color */
    color: #ffffff; /* Custom text color */
    justify-content: center;
    text-align: center;
  }

  .timeline-wrapper .Polaris-Button--textAlignCenter:hover {
    background-color: #ff4081; /* Custom background color */
    border: 2px solid #ff4081; /* Custom border color */
    color: #ffffff; /* Custom text color */
    justify-content: center;
    text-align: center;
    }

    .timeline-wrapper  .Polaris-Button--variantSecondary {
    --pc-button-box-shadow: #ff4081 !important;
  }
/* .StepProgress-item.is-done::after {
width: 30px;
height: 30px;
color: #FFF;
text-align: center;
border: 2px solid #f41289;
background-color: #f41289;
background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23ff4081"><path d="M9 16.2l-4.2-4.2-1.4 1.4 5.6 5.6 12-12-1.4-1.4z"/></svg>') no-repeat center center;
  background-size: contain;
  
} */

.StepProgress-item.is-done::after {
    /* Dimensions and alignment */
    width: 40px;
    height: 40px;
    display: inline-block;
    content: "";
    border-radius: 50%;
    text-align: center;
    line-height: 30px;
    vertical-align: middle;
  
    /* Border and background color */
    border: 8px solid #f41289;
    background-color: #f41289;
  
    /* Background image (tick SVG) */
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23ffffff"><path d="M9 16.2l-4.2-4.2-1.4 1.4 5.6 5.6 12-12-1.4-1.4z"/></svg>');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
  
    /* Optional: Color for text content if used */
    color: #fff;
  }
  

/*.StepProgress-item.current::before { 
border-left: 2px solid #f41289;
}
.StepProgress-item.current::after {
content: counter(list);
padding-top: 1px;
width: 19px;
height: 18px;
top: -4px;
left: -40px;
font-size: 14px;
text-align: center;
color: #f41289;
border: 2px solid #f41289;
background-color: white;
}*/
.StepProgress strong {
display: block;
}

.badge {
    display: inline-flex;
    align-items: center;
    padding: 0.25em 0.5em;
    border-radius: 12px;
    font-size: 12px;
    font-weight: 500;
    color: #000;
    min-width: 85px;
    justify-content: center;
  }

  .badge {
    display: inline-flex;
    align-items: center;
    padding: 0.25em 0.5em;
    border-radius: 12px;
    font-size: 12px;
    font-weight: 500;
    color: #000;
    min-width: 85px;
    justify-content: center;
  }
  
  .badge .icon {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: 8px;
    background-color: #000;
  }

  .badge-long {
    display: inline-flex;
    align-items: center;
    padding: 2px 8px;
    border-radius: 15px;
    font-size: 12px;
    font-weight: 400;
    color: #000;
    min-width: 85px;
    justify-content: center;
  }
  
  .badge-long .icon {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: 8px;
    background-color: #000;
  }
  
  
  .paid {
    background-color: #e3e3e3;
  }
  .payment-pending {
    background-color: #f8d8aa;
  }
  .awaiting-shipment {
    background-color: #ffe6e9;
  }
    .awaiting-design {
    background-color:#e277b0;
    }
    .awaiting-design .icon {
        background-color: #000;
      }
  .awaiting-shipment .icon {
    background-color: #000;
  }
  
  .awaiting-proof {
    background-color: #e9bf78;
  }

  .proof-needed {
    background-color: #93c7ab;
  }
  .design-approval-needed {
    background-color: #a59fed;
  }

  .drawing-needed {
    background-color: #e277b0;
  }

  .event-list-needed {
    background-color: #e0726e;
  }
  .proof-revision-needed {
    background-color: #d47e69;
  }
  .pending-approval {
    background-color: #f4e082;
  }


  .proof-added {
    background-color: #5072cb;
    color: #FFFFFF;
  }
  .proof-added .icon {
    background-color: #FFFFFF;
  }
  
  
  .proof-sent {
    background-color: #5072cb;
    color: #FFFFFF;
  } 

  .proof-sent .icon {
    background-color: #FFFFFF;
  }
  
  .proof-rejected {
    background-color: #e0726e;
  }
  
  .remake {
    background-color: #a6dede;
  }

  .repair {
    background-color: #72c8c3;
  }
  .proof-accepted {
    background-color: #bcde9e;
  }

  .order-fulfilled {
    background-color: #72c8c3;
  }
  
  
  .backorder {
    background-color: #fff3e6;
  }
  
 

  .production {
    background-color: #e3e3e3;
    
  }
  
  

  .default {
    background-color: #f5f5f5;
  }

  

@media (max-width: 767px) {
    .fixedElement {
        padding: 8px 8px;
    }
    .Polaris-Page__Content  {
        padding-top: 20px;
    }
    .column-body {
        align-items: center;
        padding: 5px 10px 15px;
    }
    .pdf-container-proof-sent {
        width: 100%;
        aspect-ratio: 12/9;
        background-color: #eb2310;
        border-radius: 5px;
    }
    #dt-responsive-table table {
        width: 100% !important;
    }

    /* Styles for the table header */
    #dt-responsive-table table thead {
        display: none !important; /* Hide the table header on mobile */
    }

    /* Styles for the table rows */
    #dt-responsive-table table tbody {
        display: flex !important;
        flex-direction: column !important;
        align-items: stretch !important;
        min-height: auto !important; /* Adjust the min-height as needed */
    }

    /* Styles for individual table rows (cards) */
    #dt-responsive-table table tbody tr {
        border: 1px solid #ccc !important;
        border-radius: 8px !important;
        margin-bottom: 1rem !important;
        background-color: #fff !important;
        padding: 1rem !important;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1) !important;
    }

    /* Styles for table cells within rows */
    #dt-responsive-table table tbody td {
        margin: 0.5rem 0 !important;
    }

    /* Hide individual table rows (cards) on mobile */
    #dt-responsive-table table tbody tr {
        display: table-row !important;
    }

    /* Set the display of table cells to be block-level elements */
    #dt-responsive-table table tbody td {
        display: block !important;
        margin: 0.5rem 0 !important;
    }

    .Polaris-Page {
        padding: 8px;
    }

    .btn {
        min-width: min-content !important;
    }

    .fixedElement {
        display: flex;
    }


    #order-conatainer-box .column-body {
        padding: 10px 5px !important;
    }

    #order-items-box .right-column-body {
        padding: 10px;
    }

    .web {
        display: none;
    }

    .mobile {
        display: block;
    }

    .web-mobile {
        display: block;
    }

    .web-mobile span {
        margin-right: 10px !important;
    }

    .fixedElement-Lower{
        margin: 20px 0px 30px;
    }

    .responsive-image {
        width: 350 !important;
        height: 300 !important;
        max-width: 90vw!important;
        max-height: 70vw!important;
    }

    #notes-box .right-column-body span {
        display: block;
        word-wrap: break-word;
    }

    .order-detail-right {
        display: block;
    }
    .order-detail-right .Polaris-Box {
        margin-bottom: 20px;
    }

    #notes-box {
        display: block;
    }

    #notes-box .column-header {
        margin-bottom: 20px;
    }

    .order-detail-right .Polaris-Box {
        display: grid;
        gap: 0px;
    }

    .proof-pag-wrapper {
        padding-left: 20px;
        padding-right: 20px;
    }
}